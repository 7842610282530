.section-message.about {
    height: calc(100vh);
    text-align: center;
    display: flex;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    background-color: #cbe8de;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    .content {
        max-width: 80%;
        margin: 0 auto;
    }
}

// .about {
//   max-width: 80%;
//   margin: 0 auto;
//   font-family: "Open Sans", sans-serif;
//   font-weight: 500;
//   padding: 50px 0;
//   min-height: 70vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//   flex-direction: column;
//   font-size: 20px;
//   .left {
//     width: 60%;
//   }
//   .right {
//     width: 40%;
//     background-color: white;
//     height: 100%;
//   }
// }
