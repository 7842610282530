/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 22px;
    left: 12px;
    top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
    height: 2px !important;
}

.bm-cross-button {
    width: 28px !important;
    height: 28px !important;
    right: 12px !important;
    top: 8px !important;
    transition: all 0.1s;
    &:active {
        .bm-cross {
            background: #878990;
        }
    }
    &:hover {
        .bm-cross {
            background: #878990;
        }
    }
}

/* Color/shape of close button cross */
.bm-cross {
    background: black;
    height: 22px !important;
    width: 2px !important;
}

/* General sidebar styles */
.bm-menu {
    background: white;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    a {
        color: black;
        padding: 10px 0;
    }
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
