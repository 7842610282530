@font-face {
  font-family: "Berlingske Serif";
  src: url('../assets/fonts/BerlingskeSerif-Bold.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Berlingske Serif";
  src: url("../assets/fonts/BerlingskeSerif-Lt.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
