.welcome-message {
    position: fixed;
    font-size: 16px;
    width: 100vw;
    padding: 15px;
    background-color: white;
    left: 0;
    top: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 10;
    text-align: center;
}

@media screen and (min-width: 1024px) {
    .welcome-message {
        width: 100vh;
        height: 50px;
        padding: 15px 0;
        background-color: white;
        transform-origin: top left;
        left: 0;
        top: 0;
        transform: rotate(-90deg) translateX(-100%);
        bottom: auto;
        font-size: 14px;
    }
}
