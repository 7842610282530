.quote {
  max-width: 75%;
  margin: 0 auto;
  border-bottom: 1px solid black;
  font-size: 30px;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .quote {
    margin-bottom: 48px;
    font-size: 4vw;
  }
}
