@import '../../styles/fonts.scss';

* {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

html {
    height: -webkit-fill-available;
}

h1,
h2,
h3 {
    font-size: inherit;
}
.animated {
    width: 100%;
}

.App {
    background-color: #cbe8de;
    font-family: 'Berlingske Serif', sans-serif;
    font-weight: 300;

    .section-message {
        height: calc(100vh);
        text-align: center;
        display: flex;
        min-height: 600px;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: column;
        background-color: #8c94f8;
        font-size: 16px;
        .content {
            max-width: 80%;
            margin: 0 auto;
        }
        &.bg-1 {
            background: linear-gradient(90deg, #69b7eb, #b3dbd3, #f4d6db);
        }
        &.bg-2 {
            background: #dae2f8;
            background: linear-gradient(to right, #d6a4a4, #dae2f8);
        }
        &.bg-3 {
            background: #2bc0e4;
            background: linear-gradient(to right, #eaecc6, #2bc0e4);
        }

        &.bg-4 {
            background: linear-gradient(45deg, #66a1b7, #cbf1c8, #e9acac);
        }

        &.bg-5 {
            background-image: linear-gradient(
                to left,
                #ffbbe2,
                #f3beed,
                #e5c1f5,
                #d6c5fb,
                #c6c9ff,
                #b7d0ff,
                #abd6ff,
                #a2dbff,
                #9de5ff,
                #9ceeff,
                #a0f7fe,
                #a9fff9
            );
        }
        &.bg-6 {
            background: linear-gradient(90deg, #e59a9a, #cdb0ff);
        }
        &.bg-7 {
            background: linear-gradient(90deg, #0facae, #f6edb2);
        }

        &.bg-8 {
            background: linear-gradient(90deg, #ee5c87, #f1a4b5, #d587b3);
        }

        &.bg-9 {
            background: linear-gradient(90deg, rgb(35, 156, 169), rgb(114, 221, 238));
        }
        &.bg-10 {
            background: linear-gradient(90deg, #aea4e3, #d3ffe8);
        }

        &.bg-11 {
            background: linear-gradient(90deg, #faf0cd, #fab397);
        }

        &.bg-12 {
            background: linear-gradient(90deg, #cfecd0, #a0cea7, #9ec0db);
        }
        &.bg-13 {
            background: linear-gradient(90deg, rgb(200, 239, 214) 0%, rgb(245, 189, 214) 50%, rgb(170, 200, 233) 100%);
        }
        &.bg-14 {
            background: linear-gradient(90deg, rgb(98, 244, 222) 0%, rgb(112, 122, 255) 100%);
        }
        &.bg-15 {
            background: linear-gradient(90deg, rgb(75 160 139) 0%, rgb(166 252 220) 100%);
        }
    }
}

@media screen and (min-width: 1024px) {
    .App .section-message {
        font-size: 20px;
    }
}
