.next-quote {
    position: absolute;
    bottom: 100px;
    right: 50%;
    transform: translateX(50%);
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    padding: 10px 30px;
    border: 2px solid black;
    outline: none;
    text-transform: uppercase;
    font-family: 'Open Sans', serif;
    font-weight: 500;
    transition: all 0.5s;
    width: 75%;
    border-radius: 0;
    color: black;
}

.next-quote:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 5px 15px rgba(0, 0, 0, 0.5);
}

.next-quote:active:after {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 0em;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
}

.next-quote:active {
    transform: scale(0.8);
}

@media screen and (min-width: 1024px) {
    .next-quote {
        bottom: 20px;
        right: 20px;
        width: fit-content;
        transform: none;
        &:hover {
            background-color: black;
            color: white;
        }
    }
}
