.language {
    position: fixed;
    top: 10px;
    right: 8px;
    z-index: 10;
    color: black;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    gap: 4px;
    div {
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        height: 30px;
        width: 30px;
        align-items: center;
        justify-content: center;
        transition: all 0.5s;
        background-color: rgb(226, 226, 226);
    }

    .current-language {
        background: black;
        color: white;
    }
}

@media screen and (min-width: 1024px) {
    .language {
        top: auto;
        right: auto;
        bottom: 10px;
        left: 10px;
        flex-direction: column;
        gap: 5px;
        div {
            font-size: 13px;
            height: 30px;
            width: 30px;
            &:hover {
                background-color: rgb(198, 198, 198);
            }
        }
        .current-language {
            &:hover {
                background: black;
            }
        }
    }
}
